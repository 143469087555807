import React from "react";

// Function to handle smooth scrolling with an offset
const scrollToSection = (id) => {
  const section = document.getElementById(id);
  if (section) {
    const headerOffset = 83;
    const elementPosition = section.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <img src={`${process.env.PUBLIC_URL}/img/vozzo-logo.jpg`} width={100} alt="Vozzo.ai" />
        </div>
        <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul className="nav navbar-nav navbar-right">
            {/* Updated links to use scrollToSection instead of href */}
            <li>
              <a onClick={() => scrollToSection("features")} className="page-scroll">
                Features
              </a>
            </li>
            <li>
              <a onClick={() => scrollToSection("about")} className="page-scroll">
                About
              </a>
            </li>
            <li>
              <a onClick={() => scrollToSection("services")} className="page-scroll">
                Services
              </a>
            </li>
            <li>
              <a onClick={() => scrollToSection("contact")} className="page-scroll">
                Contact
              </a>
            </li>
            {/* Updated "Get Started" button to use scrollToSection */}
            <a href="https://calendar.app.google/1cB3eqqAee5p56jL6" className="btn btn-custom btn-lg page-scroll" target="_blank">
              Schedule demo
            </a>
          </ul>
        </div>
      </div>
    </nav>
  );
};
