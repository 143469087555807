import React from "react";

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img src="img/about.jpg" className="img-responsive" alt="" />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Innovating the Future of AI-Powered Engagement</h2>
              <p>
                {props.data ? props.data.paragraph : "Loading..."}
              </p>
              <h3>Why Choose Us?</h3>
              <div className="list-style">
                <ul className="list-unstyled">
                  {props.data
                    ? props.data.Why.map((d, i) => (
                        <li key={`${d}-${i}`} className="col-xs-12">
                          {d}
                        </li>
                      ))
                    : "Loading"}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
