import React from "react";

// Function to handle smooth scrolling with an offset
const scrollToSection = (id) => {
  const section = document.getElementById(id);
  if (section) {
    const headerOffset = 83;
    const elementPosition = section.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

export const Header = (props) => {
  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row intro-content">
              {/* First Column */}
              <div className="col-md-6 intro-text">
                <h1>
                  <span>{props.data ? props.data.title : "Loading"}</span>
                </h1>
                <div>
                  <p>{props.data ? props.data.paragraph : "Loading"}</p>
                </div>
                <div className="header-cta">
                  <a onClick={() => scrollToSection("contact")} className="btn btn-custom btn-lg page-scroll">
                    Get started for free
                  </a>
                </div>
              </div>
              {/* Second Column */}
              <div className="col-md-6 intro-image">
                <img src={`${process.env.PUBLIC_URL}/img/header-image.png`} alt="Header" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
